@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: var(--color-background);
  color: var(--color-text);
  overflow: hidden;
}

:root {
  --color-text-primary:rgba(0,0,0,0.87);
  --color-text-secondary:rgba(0,0,0,0.6); /* TODO: 既存のcolorでこの変数が使われていないものがある。要整備 */
  --error-main: #D32F2F;
  --error-contrast: #fff;
  --primary-contrast: #fff;
  --components-alert-error-background: #FDEDED;
  --components-alert-error-color: #5F2120;
  --divider: rgba(0, 0, 0, 0.12);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --gray-100: #f5f5f5;
  --color-teal-200: #80CBC4;
  --color-teal-400: #26A69A;
  --background-default: #ffffff;

  /* 以下Figmaには存在しない命名がされており、ルールが不明。デザインチームと相談する */
  --color-background: #ffffff;
  --color-primary-pale: #e3f2fd;
  --color-primary-light: #90caf9;
  --color-primary-soft: #64b5f6;
  --color-primary-main: #42a5f5;
  --color-primary-deep: #2196f3;
  --color-primary-dark: #1e88e5;

  --color-gray-white: #ffffff;
  --color-gray-pale: #eeeeee;
  --color-gray-light: #dddddd;
  --color-gray-soft: #cccccc;
  --color-gray-main: #999999;
  --color-gray-deep: #666666;
  --color-gray-dark: #333333;
  --color-gray-black: #000000;

  --color-danger-pale: #ffcdd2;
  --color-danger-light: #e57373;
  --color-danger-soft: #f44336;
  --color-danger-main: #c62828;
  --color-danger-deep: #c62828;
  --color-danger-dark: #b71c1c;

  --color-alert-pale: #ffe0b2;
  --color-alert-light: #ffb74d;
  --color-alert-soft: #ff9800;
  --color-alert-main: #f57c00;
  --color-alert-deep: #ef6c00;
  --color-alert-dark: #e65100;

  --color-teal-pale: #b2dfdb;
  --color-teal-light: #80cbc4;
  --color-teal-soft: #4db6ac;
  --color-teal-main: #009688;
  --color-teal-deep: #00897b;
  --color-teal-dark: #00796b;

  --color-middle: #eeeeee;
  --color-text: #212121;
  --color-hover: #999;

  --color-modal-background: #ffffff;
  --color-modal-text: #212121;
  --color-modal-border: #e0e0e0;
  --color-input-background: #f5f5f5;
  --color-input-text: #212121;
  --color-input-border: #e0e0e0;

  --font-size-xxxs: 10px;
  --font-size-xxs: 11px;
  --font-size-xs: 12px;
  --font-size-sm: 13px;
  --font-size-md: 14px;
  --font-size-mdl: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-xxl: 22px;
  --font-size-xxxl: 24px;

  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;

  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;

  --radius: 0.5rem;
}

.color {
  --color-text-primary:rgba(0,0,0,0.87);
  --color-text-secondary:rgba(0,0,0,0.6);
  --error-main: #D32F2F;
  --error-contrast: #fff;
  --primary-contrast: #fff;
  --components-alert-error-background: #FDEDED;
  --components-alert-error-color: #5F2120;

  /* 以下Figmaには存在しない命名がされており、ルールが不明。デザインチームと相談する */
  --color-background: #ffffff;
  --color-primary-pale: #e3f2fd;
  --color-primary-light: #90caf9;
  --color-primary-soft: #64b5f6;
  --color-primary-main: #42a5f5;
  /* --primary-main: #2196f3; Figma上ではprimary-mainがこの色になっている。必要があればcolor-primary-deepの名前を変えてこちらを使う。（その場合42a5f5はなんという名前か？） */
  --color-primary-deep: #2196f3;
  --color-primary-dark: #1e88e5;

  --color-gray-white: #ffffff;
  --color-gray-pale: #eeeeee;
  --color-gray-light: #dddddd;
  --color-gray-soft: #cccccc;
  --color-gray-main: #999999;
  --color-gray-deep: #666666;
  --color-gray-dark: #333333;
  --color-gray-black: #000000;

  --color-danger-pale: #ffcdd2;
  --color-danger-light: #e57373;
  --color-danger-soft: #f44336;
  --color-danger-main: #c62828;
  --color-danger-deep: #c62828;
  --color-danger-dark: #b71c1c;

  --color-warning-pale: #ffe0b2;
  --color-warning-light: #ffb74d;
  --color-warning-soft: #ff9800;
  --color-warning-main: #f57c00;
  --color-warning-deep: #ef6c00;
  --color-warning-dark: #e65100;

  --color-teal-pale: #b2dfdb;
  --color-teal-light: #80cbc4;
  --color-teal-soft: #4db6ac;
  --color-teal-main: #009688;
  --color-teal-deep: #00897b;
  --color-teal-dark: #00796b;

  --color-middle: #eeeeee;
  --color-text: #212121;
  --color-hover: #999;

  --color-modal-background: #ffffff;
  --color-modal-text: #212121;
  --color-modal-border: #e0e0e0;
  --color-input-background: #f5f5f5;
  --color-input-text: #212121;
  --color-input-border: #e0e0e0;

  --font-size-xxxs: 10px;
  --font-size-xxs: 11px;
  --font-size-xs: 12px;
  --font-size-sm: 13px;
  --font-size-md: 14px;
  --font-size-mdl: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-xxl: 22px;
  --font-size-xxxl: 24px;

  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;

  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;

  --radius: 0.5rem;
}

.dark {
  --color-background: #111111;

  --color-primary-pale: #1e88e5;
  --color-primary-light: #2196f3;
  --color-primary-soft: #42a5f5;
  --color-primary-main: #64b5f6;
  --color-primary-deep: #90caf9;
  --color-primary-dark: #e3f2fd;

  --color-gray-white: #000000;
  --color-gray-pale: #222222;
  --color-gray-light: #444444;
  --color-gray-soft: #666666;
  --color-gray-main: #999999;
  --color-gray-deep: #cccccc;
  --color-gray-dark: #eeeeee;
  --color-gray-black: #ffffff;

  --color-danger-pale: #b71c1c;
  --color-danger-light: #c62828;
  --color-danger-soft: #c62828;
  --color-danger-main: #f44336;
  --color-danger-deep: #e57373;
  --color-danger-dark: #ffcdd2;

  --color-warning-pale: #e65100;
  --color-warning-light: #ef6c00;
  --color-warning-soft: #f57c00;
  --color-warning-main: #ff9800;
  --color-warning-deep: #ffb74d;
  --color-warning-dark: #ffe0b2;

  --color-teal-pale: #00796b;
  --color-teal-light: #00897b;
  --color-teal-soft: #009688;
  --color-teal-main: #4db6ac;
  --color-teal-deep: #80cbc4;
  --color-teal-dark: #b2dfdb;

  --color-middle: #333333;
  --color-text: #ffffff;
  --color-hover: #999;
  --color-modal-background: #212121;
  --color-modal-text: #f5f5f5;
  --color-modal-border: #e0e0e0;
  --color-input-background: #f5f5f5;
  --color-input-text: #212121;
  --color-input-border: #e0e0e0;

  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;

  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;

  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;

  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;

  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;

  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;

  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;

  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;
  --ring: 212.7 26.8% 83.9%;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
